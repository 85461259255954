<template>
  <div class="PsdDownBtn">
    <a-button style="margin: 10px"  @click="download" ><a-icon type="vertical-align-bottom" />封面psd源文件</a-button>
  </div>
</template>

<script>
export default {
  name: "ExamPsdDownLoad",
  data(){
    return{
      psd:[
        {
          id:14,
          url:'https://download-1252497858.cos.ap-guangzhou.myqcloud.com/psd/%E6%8A%A4%E7%90%86%E5%AD%A6.psd',
        },
        {
          id:18,
          url:'https://download-1252497858.cos.ap-guangzhou.myqcloud.com/psd/%E4%B8%B4%E5%BA%8A%E6%89%A7%E4%B8%9A%E5%8C%BB%E5%B8%88.psd',
        },
        {
          id:13,
          url:'https://download-1252497858.cos.ap-guangzhou.myqcloud.com/psd/%E9%87%8D%E7%97%87%E5%8C%BB%E5%AD%A6%E4%B8%AD%E7%BA%A7.psd',
        },
        {
          id:17,
          url:'https://download-1252497858.cos.ap-guangzhou.myqcloud.com/psd/%E9%87%8D%E7%97%87%E5%8C%BB%E5%AD%A6%E6%AD%A3%E9%AB%98.psd',
        },
        {
          id:16,
          url:'https://download-1252497858.cos.ap-guangzhou.myqcloud.com/psd/%E9%87%8D%E7%97%87%E5%8C%BB%E5%AD%A6-%E5%89%AF%E9%AB%98.psd',
        },
      ]

    }
  },
  methods:{
    download(){
      const url= this.psd.find(array => array.id === this.$store.state.examId)
      window.open(url.url)
    },
  }
}

</script>

<style scoped>

</style>
