<template>
<div class="practiceTest">
  <a-spin :indicator="indicator" :spinning="spinning">
    <div style="overflow: hidden;display: flex;justify-content: space-between">
      <div class="menu_select">
        <h4 style="padding: 10px">模块</h4>
        <a-menu
            style="width: 100%"
            mode="inline"
            v-model="menu"
        >
          <a-menu-item
              v-for="(item,index) in typeInfo"
              :key="item.id"
              @click="leftChange(index,item)"
          >
            {{item.name}}
          </a-menu-item>
        </a-menu>
        <div style="display: flex;justify-content: right;padding: 5px">
          <a-pagination
              size="small"
              v-model="menuPage.pageNo"
              :total="menuPage.total"
              @change="menuPageChange"/>
        </div>
      </div>
      <div class="InfoTable">

        <div class="select_Info">

          <div v-if="InfoTable.length!==0">
            所属分类：<a-button  style="margin: 5px;font-family: 微软雅黑" v-for="(list,index) in InfoTable" size="small" :class="{ liBackground:provincesStyle == index}" id="provincesBtn"
                                @click="provincesChange(index,list)">{{list.name}}</a-button>
          </div>
          <div v-else>
            所属分类： 无
          </div>
        </div>


        <div class="add_Info">
          <a-button type="primary" @click="()=>{this.addShow = true;this.leftPagination.pageNo=1; this.selectTypeList = []; this.getTypeList(1,)}">新增</a-button>
          <exam-psd-down/>
        </div>
<!--        <div style="background-color: rgba(240, 242, 245, 1);min-width: 100%;height: 10px"></div>-->
        <div class="table_Info">
          <a-table :pagination="false" :columns="columns" :data-source="tableList" rowKey="id" >

            <span slot="time" slot-scope="text,item">
              <span v-if="item.duration === null || item.duration === '' ">不限</span>
              <span v-else>{{item.duration}}</span>
              </span>
            <span slot="number" slot-scope="text,item">
                <a v-if="item.getType === 'DEFINED'" @click="examBtnModel(item)" >{{ item.questionSum }}</a>
                    <span v-else>{{item.questionSum }}</span>
                </span>
            <span slot="switch" slot-scope="text,item">
                <a-switch v-model="item.isOpen=== 1" checked-children="是" un-checked-children="否" @click="switchChange(item,item.isOpen === 1? 0 : 1)"/>
              </span>
            <span slot="operate" slot-scope="text,item,index" style="display: flex;justify-content: space-between;width: 70%">
<!--              <a v-if="index !==0" @click="moveUp(item.id)">上移</a>-->
<!--              <a v-if="index !== tableList.length - 1" @click="moveDown(item.id)">下移</a>-->
              <a @click="moveUp(item.id)">上移</a>
              <a @click="moveDown(item.id)">下移</a>
              <a @click="editBtn(item)">修改</a>
              <a-popconfirm
                  title="确定删除?"
                  ok-text="是"
                  cancel-text="否"
                  @confirm="delTitle(item.id)"
                  @cancel="cancel"
              >
            <a >删除</a>
            </a-popconfirm>
            </span>
          </a-table>
          <div style="margin-top: 10px;align-items: center;display: flex;justify-content: space-between">
            <span style="font-size: medium;color: #929292">共 {{stemPagination.total}} 条记录 第 {{ stemPagination.current }} / {{ Math.ceil(stemPagination.total / stemPagination.pageSize) }} 页</span>
            <a-pagination
                :page-size-options="stemPagination.sizeOptions"
                v-model="stemPagination.current"
                :total="stemPagination.total"
                show-size-changer
                :page-size="stemPagination.pageSize"
                @change="tableChange"
                @showSizeChange="tableChange"
                show-quick-jumper
                style="float: right"
            >
            </a-pagination>
          </div>
        </div>
      </div>
    </div>

    <a-drawer
        :get-container="false"
        title="新增"
        width="600"
        :visible="addShow"
        @close="()=>{this.addShow = false;this.addForm = {getType:'DEFINED',isOpen:0,cover:''}}"
    >
      <a-form-model :rules="rules" :model="addForm" :label-col="labelCol" :wrapper-col="wrapperCol" ref="addItem" >
<!--        <a-form-model-item label="封面图" prop="cover">-->
<!--          <div style="display: flex">-->
<!--            <div style="display: flex;flex-wrap: wrap;justify-content: space-between;width:70%;margin-bottom: -10px">-->
<!--              <div class="uploadImg">-->
<!--                <input style="cursor:pointer;width: 100px;height: 100px;opacity: 0;" accept="image/*" type="file" @change="uploadPic('addCover')" ref="addCover" />-->
<!--              </div>-->
<!--              <div class="upload_image">-->
<!--                <img-->
<!--                    alt=""-->
<!--                    v-if="addForm.cover"-->
<!--                    style="width: 95px;height:95px;"-->
<!--                    :src="addForm.cover"-->
<!--                />-->
<!--              </div>-->
<!--            </div>-->
<!--            <div style="margin-left: 10px">-->
<!--              <a @click="openSample('practiceTest_cover')" style="width: 50px;margin-top: -5px;margin-left: 3px">示例图</a>-->
<!--            </div>-->
<!--          </div>-->
<!--          <span style="font-size: 10px">注：尺寸 宽280*高158，大小不超过500kb</span>-->
<!--        </a-form-model-item>-->
        <a-form-model-item label="分类"  :prop="InfoTable.length!==0?'exeType':''">

          <a-select
              placeholder="请选择"
              v-model="addForm.exeType"
          >
            <a-select-option v-for="item in InfoTable" :key="item.id" :value="item.id">
              {{item.name}}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="名称" prop="name">
          <div style="display: flex">
            <a-input  v-model="addForm.name"  placeholder="请输入"  />
            <a @click="openSample('practiceTest_name')" style="width: 50px;margin-top: -5px;margin-left: 3px">示例图</a>
          </div>
        </a-form-model-item>
        <a-form-model-item label="年份"   :prop="router==='PAST'?'year':''">
          <a-date-picker
              mode="year"
              placeholder="请选择"
              format='YYYY'
              v-model="addForm.year"
              @panelChange="panelChangeOne($event,'add')"
              style="width:100%"
          />
        </a-form-model-item>
        <a-form-model-item label="题目类型" prop="getType">
          <a-radio-group v-model="addForm.getType"  @change="typeChange">
            <a-radio value="DEFINED" >
              自定义
            </a-radio>
<!--            <a-radio value= "RDNDOM">-->
<!--              随机-->
<!--            </a-radio>-->
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item label="时长" >
          <div style="display: flex">
            <a-input v-model="addForm.duration" placeholder="请输入"/>
            <a @click="openSample('practiceTest_duration')" style="width: 50px;margin-top: -5px;margin-left: 3px">示例图</a>
          </div>
        </a-form-model-item>
        <a-form-model-item label="题库分类" prop="questionType" >
<!--          <a-select-->
<!--              placeholder="请选择"-->
<!--              v-model="addForm.questionType"-->
<!--              @focus="getExam"-->
<!--              @blur="()=>this.ExamList=[]"-->
<!--              @click="typeKey(item.count)"-->
<!--          >-->
<!--            <a-select-option v-for="item in ExamList" :key="item.id" :value="item.id">-->
<!--              {{item.name}}-->
<!--            </a-select-option>-->
<!--          </a-select>-->


          <a-select
              @popupScroll="popupScroll"

              option-filter-prop="children"
              style="width: 100%"
              placeholder="请选择"
              v-model="addForm.questionType"
          >
            <a-select-option v-for="(select,index) in selectTypeList" :key="select.id" :value="select.id" >
              {{select.typeName}}
            </a-select-option>
          </a-select>
        </a-form-model-item>

<!--        <a-form-model-item label="随机数量" prop="questionNum" v-if="addForm.getType ==='RDNDOM' " >-->
<!--          <a-input  v-model="addForm.questionNum"  placeholder="输入数量" @change="NUmchange(addForm.questionNum)" />-->
<!--&lt;!&ndash;          <a-alert v-if="addForm.hasOwnProperty('questionType')" :message="'随机数量不要超过该分类题目总数'+count" type="warning"  close-text="x" />&ndash;&gt;-->
<!--        </a-form-model-item>-->
<!--        <a-form-model-item label="单题分值" prop="questionCcore" v-if="addForm.getType ==='RDNDOM' ">-->
<!--          <a-input v-model="addForm.questionScore" placeholder="输入分值" @change="NUmchange2(addForm.questionScore)" />-->
<!--        </a-form-model-item>-->
        <a-form-model-item label="总分" prop="score">
          <div style="display: flex">
            <a-input v-model="addForm.score" :disabled="addForm.getType === 'RDNDOM'" :placeholder="addForm.getType === 'RDNDOM' ? '随机数量*单题分值' : '请输入'" />
            <a @click="openSample('practiceTest_score')" style="width: 50px;margin-top: -5px;margin-left: 3px">示例图</a>
          </div>
        </a-form-model-item>
        <a-form-model-item label="开始说明" prop="startText">
          <a-textarea v-model="addForm.startText" placeholder="输入说明"/>
        </a-form-model-item>
        <a-form-model-item label="结束说明" prop="endText">
          <a-textarea v-model="addForm.endText" placeholder="输入说明"/>
        </a-form-model-item>
        <a-form-model-item label="是否开启" prop="isOpen">
          <a-switch @change="openWitch" checked-children="是" un-checked-children="否" default-un-checked />
        </a-form-model-item>

      </a-form-model>

      <div
          :style="{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e8e8e8',
          padding: '10px 16px',
          textAlign: 'right',
          left: 0,
          background: '#fff',
          borderRadius: '0 0 4px 4px',
        }"
      >
        <a-button style="marginRight: 8px" @click="()=>{this.addShow = false}">
          取消
        </a-button>
        <a-button type="primary" @click="addOk">
          确认
        </a-button>
      </div>
    </a-drawer>

    <a-drawer
        :get-container="false"
        title="修改"
        width="600"
        :visible="editShow"
        @close="()=>{this.editShow = false}"
    >
      <a-form-model :rules="rules" :model="editForm" :label-col="labelCol" :wrapper-col="wrapperCol" ref="editItem" >
<!--        <a-form-model-item label="封面图" prop="cover">-->
<!--          <div style="display: flex">-->
<!--            <div style="display: flex;flex-wrap: wrap;justify-content: space-between;width:70%;margin-bottom: -10px">-->
<!--              <div class="uploadImg">-->
<!--                <input style="cursor:pointer;width: 100px;height: 100px;opacity: 0;" accept="image/*" type="file" @change="uploadPic('editCover')" ref="editCover" />-->
<!--              </div>-->
<!--              <div class="upload_image">-->
<!--                <img-->
<!--                    alt=""-->
<!--                    v-if="editForm.cover"-->
<!--                    style="width: 95px;height:95px;"-->
<!--                    :src="editForm.cover"-->
<!--                />-->
<!--              </div>-->
<!--            </div>-->
<!--            <div style="margin-left: 10px">-->
<!--              <a @click="openSample('practiceTest_cover')" style="width: 50px;margin-top: -5px;margin-left: 3px">示例图</a>-->
<!--            </div>-->
<!--          </div>-->
<!--          <span style="font-size: 10px">注：尺寸 宽280*高158，大小不超过500kb</span>-->
<!--        </a-form-model-item>-->
        <a-form-model-item label="分类" :prop="InfoTable.length===0?'':'exeType'">
          <a-select
              placeholder="请选择"
              v-model="editForm.exeType"
          >
            <a-select-option v-for="item in InfoTable" :key="item.id" :value="item.id">
              {{item.name}}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="名称" prop="name">
          <div style="display: flex">
            <a-input  v-model="editForm.name"  placeholder="请输入"  />
            <a @click="openSample('practiceTest_name')" style="width: 50px;margin-top: -5px;margin-left: 3px">示例图</a>
          </div>
        </a-form-model-item>
        <a-form-model-item label="年份"   :prop="router==='PAST'?'year':''">
          <a-date-picker
              mode="year"
              placeholder="请选择"
              format='YYYY'
              v-model="editForm.year"
              @panelChange="panelChangeOne($event,'edit')"
              style="width:100%"
          />
        </a-form-model-item>
        <a-form-model-item label="题目类型" prop="getType">
          <a-radio-group v-model="editForm.getType"  @change="typeEdit">
            <a-radio value="DEFINED" >
              自定义
            </a-radio>
<!--            <a-radio value= "RDNDOM">-->
<!--              随机-->
<!--            </a-radio>-->
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item label="时长" >
          <div style="display: flex">
            <a-input v-model="editForm.duration" placeholder="请输入"/>
            <a @click="openSample('practiceTest_duration')" style="width: 50px;margin-top: -5px;margin-left: 3px">示例图</a>
          </div>
        </a-form-model-item>
        <a-form-model-item label="题库分类" prop="questionType"   >
          <a-select
              @popupScroll="popupScroll"

              option-filter-prop="children"
              style="width: 100%"
              placeholder="请选择"
              v-model="editForm.questionType"
          >
            <a-select-option v-for="(select,index) in selectTypeList" :key="select.id" :value="select.id" >
              {{select.typeName}}
            </a-select-option>
          </a-select>
<!--          <a-select-->
<!--              placeholder="请选择"-->
<!--              v-model="editForm.questionType"-->
<!--              @blur="()=>this.ExamList=[]"-->

<!--          >-->
<!--            <a-select-option v-for="item in ExamList" :key="item.id" :value="item.id"  @click="typeKey(item.count)">-->
<!--              {{item.name}}-->
<!--            </a-select-option>-->
<!--          </a-select>-->
        </a-form-model-item>
        <a-form-model-item label="随机数量" prop="questionNum" v-if="editForm.getType ==='RDNDOM' " >
          <a-input  v-model="editForm.questionNum"  placeholder="输入数量" @change="NUmEdit(editForm.questionNum)" />
        </a-form-model-item>
        <a-form-model-item label="单题分值" prop="questionScore" v-if="editForm.getType ==='RDNDOM' ">
          <a-input v-model="editForm.questionScore" placeholder="输入分值" @change="NUmEdit2(editForm.questionScore)" />
        </a-form-model-item>
        <a-form-model-item label="总分" prop="score">

          <div style="display: flex">
            <a-input v-model="editForm.score" :disabled="editForm.getType === 'RDNDOM' ? true : false"  :placeholder="editForm.getType === 'RDNDOM' ? '随机数量*单题分值' : '请输入'" />
            <a @click="openSample('practiceTest_score')" style="width: 50px;margin-top: -5px;margin-left: 3px">示例图</a>
          </div>

        </a-form-model-item>
        <a-form-model-item label="开始说明" prop="startText">
          <a-textarea v-model="editForm.startText" placeholder="输入说明"/>
        </a-form-model-item>
        <a-form-model-item label="结束说明" prop="endText">
          <a-textarea v-model="editForm.endText" placeholder="输入说明"/>
        </a-form-model-item>
        <a-form-model-item label="是否开启" prop="isOpen">
          <a-switch :checked="editForm.isOpen!==0" @click="openEdit(editForm.isOpen === 1? 0 : 1)"
                    checked-children="是" un-checked-children="否"  />
        </a-form-model-item>

      </a-form-model>

      <div
          :style="{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e8e8e8',
          padding: '10px 16px',
          textAlign: 'right',
          left: 0,
          background: '#fff',
          borderRadius: '0 0 4px 4px',
        }"
      >
        <a-button style="marginRight: 8px" @click="()=>{this.editShow = false}">
          取消
        </a-button>
        <a-button type="primary" @click="editOk">
          确认
        </a-button>
      </div>
    </a-drawer>
<!--  题目弹框-->
    <a-modal v-model="examShow" title="题目" width="70%" :footer="null" @cancel="cancelBtn">
      <div style="display: flex;justify-content: space-between">
        <div>
          名称：<a-input style="width: 150px;margin-right: 10px" v-model="searchName"  @keyup.enter="searchEnter"/>
          <a-button  @click="searchExam">查询</a-button>
        </div>
        <div style="width: 50%;">
<!--        <div style="width: 35%;display: flex;justify-content: space-between">-->
<!--          <a-button type="primary" @click="()=>{this.addExamShow = true}">新增</a-button>-->
<!--          <a-button type="primary">从题库选择</a-button>-->
          <a-select
              @popupScroll="popupScroll"

              option-filter-prop="children"
              style="width: 300px"
              placeholder="选择分类"
              v-model="selectType"
          >
            <a-select-option v-for="(select,index) in selectTypeList" :key="index" :value="select.typeName" @click="typeListChange(select)">
              {{select.typeName}}
            </a-select-option>
          </a-select>
          <a-button @click="mockPort" type="primary" style="margin-left: 10px">按分类导入</a-button>
        </div>
      </div>
      <a-table style="margin: 10px;min-height: 400px" :columns="ExamTitle" :data-source="textExam" rowKey="id" :pagination="false">
           <span slot="name" slot-scope="text,item,index" >
           {{'【'}} {{item.type === 'radio' ? '单选':'多选'}}{{'】'}}{{item.name}}
          </span>
        <span slot="num" slot-scope="text,item">
          <a :style="{'color': (item.questionScore ===0 ? 'red': '')}" @click="examNumBtn(item.id)">{{item.hasOwnProperty('questionScore') ? item.questionScore : 0 }}</a>
        </span>
        <span slot="operate" slot-scope="text,item,index">
           <a v-if="index !== 0" @click="examUp(item.id)">上移</a>
                <a  v-if="index !== textExam.length - 1" @click="examDown(item.id)">下移</a>
              <a style="margin-right: 10px;margin-left: 10px" @click="editBtnExam(item.id)">修改</a>
              <a-popconfirm
                  title="确定删除?"
                  ok-text="是"
                  cancel-text="否"
                  @confirm="delExam(item.id)"
                  @cancel="cancel"
              >
            <a >删除</a>
            </a-popconfirm>
          </span>
      </a-table>
      <div  style="display: flex;justify-content: flex-end;right: 35px;margin-top: 15px;">
        <a-pagination v-model="pagination.current"
                      :total="pagination.total"
                      @change="examPageChange" />
      </div>
    </a-modal>
<!--    题目新增-->
    <a-drawer
        title="新增"
        width="600"
        :visible="addExamShow"
        @close="()=>{this.addExamShow = false}"
    >
      <a-form-model :rules="rules" :model="addExamForm" :label-col="labelCol" :wrapper-col="wrapperCol" ref="addExamItem" >
        <a-form-model-item label="类型" prop="type">
          <a-radio-group v-model="addExamForm.type">
            <a-radio value="radio" >
              单选
            </a-radio>
            <a-radio value= "checkbox">
              多选
            </a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item label="分类" >
          <a-select
              placeholder="选择分类"
              v-model="addExamForm.questionCode"
              mode="multiple"
              :maxTagCount= 3
              showArrow
              :maxTagTextLength = 7
              @focus="()=>{this.getExamList()}"
              @blur="()=>{this.examName =[]}"
          >
            <a-select-option v-for="item in examName" :key="item.id" :value="item.code">
              {{item.name}}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="题目名称" prop="name">
          <a-input  v-model.trim="addExamForm.name"  placeholder="输入名称"  @change="addTitle" />
          <ul  class="titleList" v-for="item in titleList">
            {{item.name}}
          </ul>
        </a-form-model-item>
        <a-form-model-item label="选项" prop="options">
          <div style="display: flex;align-items: center"  v-for="(item,index) in addExamForm.options" :key="index">
            <a-input placeholder="输入选项" style="width: 220px;margin-right: 10px" v-model="item.title" />
            <a-icon type="plus-circle" v-if="index <=0" style="cursor: pointer;font-size: 18px;color: #1890ff;margin-right: 10px"  @click="addOptions" />
            <a-icon type="minus-circle" v-if="index!==0" style="cursor: pointer;font-size: 18px;margin-right: 10px"   @click="delOptions(item,index)" />
            <input  type="checkbox" v-model="item.answer" v-if="addExamForm.type==='checkbox'" :id="'check'+index" @change="resultKey(item,index)">
            <input  type="radio" name="radio"  v-if="addExamForm.type==='radio'" :id="'check'+index" @change="resultRadio(item,index)">
            正确答案
          </div>
        </a-form-model-item>
        <a-form-model-item label="解析" >
          <a-textarea v-model="addExamForm.parse" />
        </a-form-model-item>
      </a-form-model>

      <div
          :style="{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e8e8e8',
          padding: '10px 16px',
          textAlign: 'right',
          left: 0,
          background: '#fff',
          borderRadius: '0 0 4px 4px',
        }"
      >
        <a-button style="marginRight: 8px" @click="()=>{this.addExamShow = false}">
          取消
        </a-button>
        <a-button type="primary" @click="addExamOk">
          确认
        </a-button>
      </div>
    </a-drawer>
<!--    题目修改-->
    <a-drawer
        title="修改"
        width="600"
        :visible="editExamShow"
        @close="()=>{this.editExamShow = false}"
    >
      <a-form-model :rules="rules" :model="editExamForm" :label-col="labelCol" :wrapper-col="wrapperCol" ref="editExamItem" >
        <a-form-model-item label="类型" prop="type">
          <a-radio-group v-model="editExamForm.type">
            <a-radio value="radio" >
              单选
            </a-radio>
            <a-radio value= "checkbox">
              多选
            </a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item label="分类" >
          <a-select
              placeholder="选择分类"
              v-model="editExamForm.questionCode"
              mode="multiple"
              :maxTagCount= 3
              showArrow
              :maxTagTextLength = 7
          >
            <a-select-option v-for="item in examName" :key="item.id" :value="item.code">
              {{item.name}}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="题目名称" prop="name">
          <a-input  v-model="editExamForm.name"  placeholder="输入名称"  @change="addTitle" />
          <ul  class="titleList" v-for="item in titleList">
            {{item.name}}
          </ul>
        </a-form-model-item>
        <a-form-model-item label="选项" prop="options">
          <div style="display: flex;align-items: center"  v-for="(item,index) in editExamForm.options" :key="index">
            <a-input placeholder="输入选项" style="width: 220px;margin-right: 10px" v-model="item.title" />
            <a-icon type="plus-circle" v-if="index <=0" style="cursor: pointer;font-size: 18px;color: #1890ff;margin-right: 10px"  @click="editOptions" />
            <a-icon type="minus-circle" v-if="index!==0" style="cursor: pointer;font-size: 18px;margin-right: 10px"   @click="delEditOptions(item,index)" />
            <input  type="checkbox" v-model="item.answer" v-if="editExamForm.type==='checkbox'" :id="'check'+index" @change="resultKeyEdit(item,index)">
            <input  type="radio" name="radio" :value="item.answer" v-if="editExamForm.type==='radio'" :id="'radio'+index" @change="resultRadioEdit(item,index)">
            正确答案
          </div>
        </a-form-model-item>
        <a-form-model-item label="解析" >
          <a-textarea v-model="editExamForm.parse" />
        </a-form-model-item>
      </a-form-model>

      <div
          :style="{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e8e8e8',
          padding: '10px 16px',
          textAlign: 'right',
          left: 0,
          background: '#fff',
          borderRadius: '0 0 4px 4px',
        }"
      >
        <a-button style="marginRight: 8px" @click="()=>{this.editExamShow = false}">
          取消
        </a-button>
        <a-button type="primary" @click="editExamOk">
          确认
        </a-button>
      </div>
    </a-drawer>
<!--    分值弹框-->
    <a-modal v-model="examNumShow" title="分值"  @ok="examNumOk" >
      <div >分值：<a-input style="width: 60%" v-model="examNum" /></div>
    </a-modal>

    <PublicSample :propList="propList"></PublicSample>
  </a-spin>
</div>
</template>

<script>
import {update} from "@/utils/update";
import {
  delTest, delTitleExam, getExamLeft, getExamLeftNew, getExamOpenType, getExamTemplate, getMockExamListNew, getTestExam,
  getTestId,
  getTestList, getTextExam,
  getTitleExam,
  getTitleExamList, getTitleId, getTitleList, mockPort, postDownTextExam, postNewTextExam,
  postTest,
  postTestDown, postTestUp, postUpTextExam, putExamNumScore, putMoveMockExam, putNewExamScore,
  putTest, putTitleExam, questionPort, questionType
} from "@/service/titleExam_api";
import {get_experts_id} from "@/service/MedicalConference_y";
import ExamPsdDown from '@/components/ExamPsdDownLoad'
import PublicSample from "@/utils/PublicSample";
import moment from "moment";
export default {
  name: "practiceTest",
  components: {
    ExamPsdDown,
    PublicSample
  },
  data() {
    return {
      exeTypeId:'',
      router:this.$route.name,
      InfoTable:[],
      provincesStyle:0,
      menuPage:{
        total:0,
        pageNo: 1,
        pageSize:10,
      },
      propList:{
        visible:false,
        code:null,
      },
      addShow: false,
      editShow: false,
      spinning: false,
      examShow: false,
      editExamShow: false,
      examNumShow: false,
      selectType:undefined,
      selectTypeList:[],
      typeName:'',
      typeCount:null,
      leftPagination:{
        pageNo:1,
        title:"",
      },
      indicator: <a-icon type="loading" style="font-size: 24px" spin/>,
      labelCol: {span: 5},
      wrapperCol: {span: 16},
      searchName: '',
      examNum: undefined,
      addExamShow: false,
      addExamForm: {
        options: [
          {
            answer: 0,
            title: ''
          }
        ],
        questionCode: [],
        parse:''
      },
      addForm: {
        // questionNum: 0,
        // questionScore: 0,
        // score: undefined,
        getType:'DEFINED',
        cover: '',
        isOpen: 0,
        year:'',
      },
      editForm: {
        // questionNum: 0,
        // questionScore: 0,
        // score: undefined,
      },
      titleList: [],
      menu: [0],
      typeInfo: [],
      ExamList: [],
      typeList: [],
      tableList: [],
      textExam: [],
      editExamForm: [],
      examName: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
      },
      stemPagination:{
        sizeOptions:['10','30', '50'],
        total:0,
        current: 1,
        pageSize:10,
      },
      rules: {
        exeType:[{ required: true, message: '请选择', trigger: 'change' }],
        year:[{required: true, message: '请选择', trigger: 'change'}],
        type: [{required: true, message: '请选择分类', trigger: 'blur'}],
        questionType: [{required: true, message: '请选择', trigger: 'blur'}],
        questionNum: [{required: true, message: '请输入数量', trigger: 'blur'}],
        cover: [{required: true, message: '请上传封面图', trigger: 'blur'}],
        name: [{required: true, message: '请输入名称', trigger: 'blur'}],
        score: [{required: true, message: '请输入', trigger: 'blur'}],
        getType: [{required: true, message: '请选择类型', trigger: 'blur'}],
        questionScore: [{required: true, message: '请输入', trigger: 'blur'}],
        key: [{required: true, message: '请输入', trigger: 'blur'}],
        titleExam:[{ required: true, message: '请输入名称', trigger: 'blur' }],
        parse:[{ required: true, message: '请输入解析', trigger: 'blur' }],
      },
      ExamTitle: [
        {
          title: '序号',
          dataIndex: 'index',
          key: 'index',
          customRender: (text, record, index) => `${index + 1}`,
          align: 'center',
          width: '8%'
        },
        {
          title: '题目名称',
          scopedSlots: {customRender: 'name'},
        },
        {
          title: '分值',
          width: '8%',
          scopedSlots: {customRender: 'num'}
        },
        {
          title: '操作',
          scopedSlots: {customRender: 'operate'},
          width: '20%'
        },
      ],
      count:null,
      columns: [
        {
          title: '名称',
          dataIndex: 'name',
        },
        {
          title: '年份',
          dataIndex: 'year'
        },
        {
          title: '时长',
          scopedSlots: {customRender: 'time'},
        },
        {
          title: '总分数',
          dataIndex: 'score'
        },
        {
          title: '题目总数',
          scopedSlots: {customRender: 'number'},
        },
        {
          title: '是否开启',
          scopedSlots: {customRender: 'switch'},
        },
        {
          title: '操作',
          width:'25%',
          scopedSlots: {customRender: 'operate'},
        },
      ],
    }
  },
  watch:{
    $route(to) { //监控路由地址变更
      this.router=to.name
      this.getTest(1)
    },
  },
  created() {

    this.$store.dispatch('setManageHeaderTitle',"模拟考试管理")
    const user_info = window.localStorage.getItem("user_info");
    this.user_name =  JSON.parse(user_info).user_name
  },
   mounted() {
     this.getTest(1)
     this.getTestExam()
  },
  methods:{
    // async getTest(){
    //   // this.spinning =true
    //   const Info = await  getTestList()
    //   if (Info.code === 0){
    //     this.typeInfo = Info.data
    //     this.menuId = Info.data[0].id
    //     this.tableList = Info.data[0].mocks
    //   }
    //   // this.spinning = false
    // },
    async getTest(pageNum){
      // this.spinning =true
      const Info = await  getExamTemplate(pageNum)
      if (Info.code === 200){
        this.typeInfo = Info.data
        this.menu[0] = Info.data[0].id
        this.menuPage.total=Info.count
        this.getTypeInfo(this.typeInfo[0].id)
        // this.$store.state.examId=Info.data[0].id
        // this.addForm.type=Info.data[0].id
        // this.tableList = Info.data[0].mocks
      }
      // this.spinning = false
    },

    //获取所属分类
    async getTypeInfo(typeId){
      const exam = await getExamOpenType(this.$route.name,typeId)
      if (exam.code === 200){
        this.InfoTable = exam.data

        this.typeId =typeId
        this.exeTypeId=exam.data.length!==0?exam.data[0].id:''

        this.getTestExam(this.$route.name)
      }else {
        this.$message.error('接口获取出错' + exam.message)
      }

    },




//所属分类选择
    provincesChange(index,e){
      this.provincesStyle = index;
      this.exeTypeId = e.id
      this.getTestExam(this.$route.name)
    },
    menuPageChange(value){
      this.getTest(value)
    },
    //获取模拟考试/历年真题
    async getTestExam(board){
      this.spinning =true
      let data = {
        type:this.typeId,
        exeType:this.exeTypeId,
        board:this.$route.name,
        page:this.stemPagination.current,
        pageSize:this.stemPagination.pageSize,
      }
      const Info = await  getMockExamListNew(data)
      if (Info.code === 200){
        this.tableList = Info.data
        this.stemPagination.total = Info.count
      }
      this.spinning = false
    },
    tableChange(current, pageSize){
      this.stemPagination.current = current
      this.stemPagination.pageSize = pageSize
      this.getTestExam()
    },
    async getExamList(TitleExam){
      const exam = await getTitleExam(TitleExam)
      if (exam.code === 0){
        this.examName = exam.data
      }else {
        this.$message.error('接口获取出错' + exam.message)
      }
    },
    //左侧选择
    leftChange(value,row){
      this.typeId=row.id
      this.getTypeInfo(row.id)

      // this.menu[0] = value.key
      // this.$store.state.examId=value.key
      // this.stemPagination.current = 1
      // this.stemPagination.pageSize = 10
      // this.getTestExam()
    },
    //新增修改分类选择
    addTypeChange(e,type){
      switch (type){
        case 'add':
          this.$set(this.addForm, 'type', e)
          break;
        case 'edit':
          this.$set(this.editForm, 'type', e)
              break;
      }
    },
    panelChangeOne(value,type){
      switch (type) {
        case 'add':
          this.$set(this.addForm, 'year',  moment(value).format('YYYY'))
          break;
        case 'edit':
          this.$set(this.editForm, 'year',  moment(value).format('YYYY'))
          break;
    }
    },
    //修改是否开启
    async switchChange(row,open){

      row.isOpen=open
      const Info = await  putTest(row)
      if (Info.code === 200){
        // await this.getTest()
        // this.tableList = this.typeInfo[this.menu].mocks
        this.getTestExam()
        this.$message.success('操作成功')
      }else {
        this.$message.error('操作失败' + Info.message)
      }
    },
    //上移
    async moveUp(id){
      const move = await putMoveMockExam(id,'up')
      if (move.code === 200){
        // await this.getTest()
        // this.tableList = this.typeInfo[this.menu].mocks
        this.getTestExam()
        this.$message.success('操作成功')
      }
    },
    //下移
    async moveDown(id){
      const  move = await putMoveMockExam(id,'down')
      if (move.code === 200){
        // await this.getTest()
        // this.tableList = this.typeInfo[this.menu].mocks
        this.getTestExam()
        this.$message.success('操作成功')
      }
    },
    openWitch(checked){
      if (checked === false){
        this.addForm.isOpen = 0
      }else {
        this.addForm.isOpen = 1
      }
    },

    //获取题目
    async examBtnModel(item){
      this.examId = item.id
      this.examStore = item.score
      const exam =await getTextExam(item.id)
      if (exam.code === 0){
        this.textExam = exam.data.mtype
        this.pagination.total = exam.data.count
        //获取分类
        await this.getTypeList(this.leftPagination.pageNo,this.leftPagination.title)
        this.examShow =true
      }
    },
    //获取题目
    async examBtn(id,pageNum,name,score){
      this.examId = id
      this.examStore = score
      const exam =await getTextExam(id,pageNum,name)
      if (exam.code === 0){
        this.textExam = exam.data.mtype
        this.pagination.total = exam.data.count
        this.examShow =true
      }
    },
    //题目是否重复
    async addTitle(e){
      if (e.target.value !== '' & e.target.value !== undefined && e.target.value !== null){
        const search =await getTitleList(e.target.value)
        this.titleList = search.data
        let arr = []
        arr.push({
          name: e.target.value
        })
        for(let i = 0; i < this.titleList.length; i++) {
          for(let j = 0; j < arr.length; j++) {
            if(arr[j].name === this.titleList[i].name){
              this.$message.error('题目名称重复，重新输入')
            }}}
      }else {
        this.titleList = []
      }
    },
    typeKey(count){
      this.count=count
    },
    //搜索题目
    searchExam(){
      this.examBtn(this.examId,'',this.searchName)
      this.pagination.current = 1
    },
    //搜索题目回车
    searchEnter(){
      this.searchExam()
    },
    examNumBtn(id){
      this.editExamId = id
      this.examNumShow = true
    },
    //设置分值
    async examNumOk(){
      if (this.examNum !== '' && this.examNum !== undefined){
        const data={
          score :this.examNum
        }
        const num =await putNewExamScore(this.editExamId,data)
        await putExamNumScore(this.examId)
        if (num.code === 0){
          await this.examBtn(this.examId,this.pagination.current)
          // await this.getTest()
          // this.tableList = this.typeInfo[this.menu].mocks
          await this.getTestExam()
          this.examNumShow = false
          this.$message.success('设置成功')
        }
      }else {
        this.$message.warning('输入有空')
      }

    },
    //题目分页
    examPageChange(e){
      this.examBtn(this.examId,e)
    },
    //题目下移
    examDown(id){
      postDownTextExam(id,this.examId)
      this.timer = setTimeout(()=>{
         this.examBtn(this.examId,this.pagination.current)
        this.$message.success('操作成功')
      },1000);
    },
    //题目上移
    examUp(id){
      postUpTextExam(id,this.examId)
      this.timer = setTimeout(()=>{
         this.examBtn(this.examId,this.pagination.current)
        this.$message.success('操作成功')
      },1000);
    },
    //新增选项
    addOptions(){
      let ifTrue = true
      this.addExamForm.options.forEach(item => {
        if (item.title === '') {
          this.$message.warning('请完善选项')
          ifTrue = false
        }})
      if (ifTrue) {
        this.addExamForm.options.push({
          answer:0,
          title:''
        })}
    },
    //修改题目选项
    editOptions(){
      let ifTrue = true
      this.editExamForm.options.forEach(item => {
        if (item.title === '') {
          this.$message.warning('请完善选项')
          ifTrue = false
        }})
      if (ifTrue) {
        this.editExamForm.options.push({
          answer:0,
          title:''
        })}
    },

    resultRadio(row,index){
      row.answer = 1
      this.addExamForm.options.forEach((value,subIndex)=>{
        if (subIndex!==index){
          value.answer=0
        }
      })
    },
    resultRadioEdit(row,index){
      row.answer = 1
      this.editExamForm.options.forEach((value,subIndex)=>{
        if (subIndex!==index){
          value.answer=0
        }
      })
    },


    //删除选项
    delOptions(item,index){
      this.addExamForm.options.splice(index, 1)
    },
    //修改删除选项
    delEditOptions(item,index){
      this.editExamForm.options.splice(index, 1)
    },
    //新增选项正确答案
    resultKey(row,index){
      let all = document.getElementById("check"+index);
      if (all.checked===true){
        row.answer = 1
      }else {
        row.answer = 0
      }
    },
    //修改是否正确答案
    resultKeyEdit(row,index){
      let all = document.getElementById("check"+index);
      if (all.checked===true){
        row.answer = 1
      }else {
        row.answer = 0
      }
    },
    //修改题目btn
    async editBtnExam(id){
      this.editExamId = id
      const edit =await getTitleId(id)
      if (edit.code === 0){
        this.editExamForm = edit.data
      }
      this.editExamShow = true
    this.getExamList()
    },
    async add(){
      this.$refs.addExamItem.validate(async valid =>{
        if(valid){
          let arr =[]
          for (let i=0;i<this.addExamForm.options.length;i++){
            arr.push({
              title:this.addExamForm.options[i].title,
              answer:this.addExamForm.options[i].answer,
              id:i
            })
          }
          this.addExamForm.options =arr
          const add =await postNewTextExam(this.examId,this.user_name,this.addExamForm)
          if (add.code === 0){
            await this.examBtn(this.examId,this.pagination.current)
            this.$message.success('添加成功')
            this.$refs.addExamItem.resetFields();
            this.addExamForm={
              options:[
                {
                  answer:0,
                  title:''
                }
              ],
              questionCode:[],
              parse:''
            }
            this.addExamShow = false
          }
        }else {
          this.$message.error("输入有空~")
          return false;
        }
      })
    },
    //新增题目
     addExamOk(){
       for(let i = 0; i < this.addExamForm.options.length; i++) {
         if ( this.addExamForm.options[i].answer === 1 ){
           this.add()
           return true;
         }
       }
       this.$message.error('未选择正确答案')
       return false;

    },
    async edit(){
      this.$refs.editExamItem.validate(async valid =>{
        if(valid){
          let arr =[]
          for (let i=0;i<this.editExamForm.options.length;i++){
            arr.push({
              title:this.editExamForm.options[i].title,
              answer:this.editExamForm.options[i].answer,
              id:i
            })
          }
          this.editExamForm.options =arr
          const add =await putTitleExam(this.editExamId,this.editExamForm)
          if (add.code === 0){

            await this.examBtn(this.examId,this.pagination.current)
            this.$message.success('修改成功')
            this.$refs.editExamItem.resetFields();
            this.editExamShow = false
          }
        }else {
          this.$message.error("输入有空~")
          return false;
        }
      })
    },
    // 修改题目
     editExamOk(){
       for(let i = 0; i < this.editExamForm.options.length; i++) {
         if (this.editExamForm.options[i].answer === 1 ){
           this.edit()
           return true;
         }
       }
       this.$message.error('未选择正确答案')
       return  false;
    },

    NUmchange(e){
      if (this.addForm.getType ==='RDNDOM'){
        this.addForm.score = e * this.addForm.questionScore
      }
    },
    NUmchange2(e){
      if (this.addForm.getType ==='RDNDOM'){
        this.addForm.score = this.addForm.questionNum * e
      }
    },
    typeChange(e){
      if ( e.target.value === 'DEFINED'){
        delete this.addForm.questionNum
        delete this.addForm.questionScore
        delete this.addForm.questionType
        this.addForm.score = 0
      }
    },
    typeEdit(e){
      if ( e.target.value === 'DEFINED'){
        delete this.editForm.questionNum
        delete this.editForm.questionScore
        delete this.editForm.questionType
        this.editForm.score = 0
      }
    },
    NUmEdit(e){
      if (this.addForm.getType ==='RDNDOM'){
        this.editForm.score = e * this.editForm.questionScore
      }
    },
    NUmEdit2(e){
      if (this.editForm.getType ==='RDNDOM'){
        this.editForm.score = this.editForm.questionNum * e
      }
    },

    //获取职称考试分类
    async getExamType(){
      const Info =await getTitleExamList()
      if (Info.code ===0){
        this.typeList = Info.data.etypes
      }
    },
    //获取题库分类
    async getExam(){
      const Info =await getTitleExam()
      this.ExamList = Info.data
    },
    async uploadPic(type){
      const _this = this;
      let file = this.$refs[type].files[0];
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function(e) {
        let image = new Image();
        image.src = e.target.result;
        image.onload = async function () {
          let w = this.width;
          let h = this.height;
          if ( file.size > 500*1024) {
            _this.$message.warn('请上传大小500k以内的图片');
            return false;
          } else {
            const image = await update(file);
            if (image.code === 0) {
              switch (type) {
                case'addCover':
                  _this.addForm.cover = image.data.url
                  break;
                case'editCover':
                  _this.editForm.cover = image.data.url
                  break;
              }
              _this.$message.success('上传成功')
            } else {
              _this.$message.error("上传失败，请重试。", image.message)
            }
          }
        }
      }
    },
    //新增模拟考试
    async addOk(){
      this.$refs.addItem.validate(async valid =>{
        if(valid){
          this.addForm.type=this.typeId
          this.addForm.createdByName=this.user_name
          this.addForm.board=this.$route.name
          console.log(this.addForm)
          const add =await postTest(this.addForm)
          if (add.code === 200){
            this.getTestExam()
            // this.tableList = this.typeInfo[this.menu].mocks
            this.addForm.duration=''
            this.$message.success('添加成功')
            this.$refs.addItem.resetFields();
            this.addShow = false
          }
        }else {
          this.$message.error("输入有空~")
          return false;
        }
      })
    },
    //修改是否开启
    openEdit(open){
      this.editForm.isOpen = open
    },
    //模拟考试修改按钮
     editBtn(row){
      this.editId= row.id
      this.editForm=JSON.parse(JSON.stringify(row))
       this.selectTypeList = []
       this.leftPagination.pageNo=1
      this.getTypeList(1)
      // const edit =await getTestId(id)
      // if (edit.code === 0){
      //   this.editForm = edit.data
      // }
       this.getExamType()
      // await this.getExam()
      this.editShow = true
    },
    //修改模拟考试
    async editOk(){
      this.$refs.editItem.validate(async valid =>{
        if(valid){

          const add =await putTest(this.editForm)
          if (add.code === 200){
            // await this.getTest()
            // this.tableList = this.typeInfo[this.menu].mocks
            this.getTestExam()
            this.$message.success('修改成功')
            this.$refs.editItem.resetFields();
            this.editShow = false
          }
        }else {
          this.$message.error("输入有空~")
          return false;
        }
      })
    },
    async delExam(id){
      const Info =await delTitleExam(id)
      if (Info.code === 0){
        await this.examBtn(this.examId)
        this.$message.success('删除成功')
      }else {
        this.$message.error('删除失败')
      }
    },
    //删除模拟考试
    async delTitle(id){
      const Info =await delTest(id)
      if (Info.code === 200){
        // await this.getTest()
        this.getTestExam()
        // this.tableList = this.typeInfo[this.menu].mocks
        this.$message.success('删除成功')
      }else {
        this.$message.error('删除失败')
      }
    },
    //取消删除
    cancel(){
      this.$message.warning('取消删除');
    },
    //获取分类列表
    async getTypeList(pageNo,title) {
      let data = {
        page:pageNo,
        examTypeId:this.typeId,
        board: this.$route.name
      }
      const exam = await getExamLeftNew(data)
      if (exam.code === 200 && exam.data.length){
        let list  = exam.data
        for (let i=0;i<list.length;i++){
          this.selectTypeList.push({
            count: list[i].count,
            typeName: list[i].name,
            id: list[i].id,
          })
        }
      }
    },
    //滚动条监听
    popupScroll(e){
      const {target} = e
      const scrollHeight = target.scrollHeight - target.scrollTop //滑动总高度
      const clientHeight = target.clientHeight //滑动最低高度
      if(scrollHeight < clientHeight + 2){
        this.leftPagination.pageNo++
        this.getTypeList(this.leftPagination.pageNo,this.leftPagination.title)
      }
    },
    //鼠标搜索
    async searchTypeList(value) {
      if(this.selectTypeList.length){
        //首先清空数组，否则会数据重复
        this.selectTypeList = []
        this.leftPagination.title = value
        this.leftPagination.pageNo = 1
        await this.getTypeList(this.leftPagination.pageNo, this.leftPagination.title)
      }
    },
    typeListChange(item){
      this.typeName = item.typeName
      this.typeCount = item.count
    },
    //导入按钮
    async mockPort() {
      if(this.typeName ){
        if(this.typeCount ){
        // let score = (this.examStore / this.typeCount) > 1 ? Math.round((this.examStore / this.typeCount)): 1
        // console.log(this.examStore,this.typeCount)
        let data = {
          mockId: this.examId,
          //每道题目的分值。题目分值除以题目个数
          score: 1,
          codeName: this.typeName
        }
        const exam = await mockPort(data)
        if (exam.code === 0) {
          this.$message.success("导入成功")
          await this.examBtn(this.examId)
          // //清空select值，并重新加载select列表，防止导入重复的题库
          this.leftPagination.pageNo = 1
          this.leftPagination.title = ''
          this.selectType = undefined
          this.selectTypeList = []
          await this.getTypeList(this.leftPagination.pageNo,this.leftPagination.title)
        } else {
          this.$message.error(exam.message)
        }
        }else {
          this.$message.warning('您选择的题库分类中没有数据，请先上传题库后再进行导入！')
        }
      }else {
        this.$message.warning('请选择分类后再导入！')
      }
    },
    cancelBtn(){
      this.examShow = false
      this.getTestExam()
      this.typeName = ''
      this.selectType = undefined
      this.selectTypeList = []
      this.leftPagination.pageNo = 1
      this.leftPagination.title = ''
    },
    openSample(code){
      // code加上时间防止重复
      this.propList = {
        visible:true,
        code:code+'202303220923',
      }
    },
  }

}
</script>

<style lang="scss" scoped>
.liBackground {
  background: #1890ff !important;
  color: white;
}
.titleList{
  padding: 0;
  margin: 0;
}
.uploadImg{
  border: #DDDDDD 2px dashed;
  width: 100px;
  height: 100px;
  margin: 0 auto;
  background-image: url("../../../assets/add.png");
  background-size:100% 100%;
  background-repeat:no-repeat;
  cursor: pointer;
}
.upload_image{
  border: #DDDDDD 2px dashed;
  width: 100px;
  height: 100px;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}
.practiceTest{
  margin: -15px -15px;
  background-color: rgba(240, 242, 245, 1);
  .menu_select{
    background-color: white;
    width: 18%;
    height: 100%;
    margin-bottom: -1000px;
    padding-bottom: 1000px;
  }
  .InfoTable{
    width: 81%;
    margin-bottom: -1000px;
    padding-bottom: 1000px;
    .select_Info{
      display: flex;
      height: 60px;
      padding: 15px;
      align-items: center;
      margin-bottom: 10px;
      background-color: white;
    }
    .add_Info{
      display: flex;
      height: 50px;
      align-items: center;
      justify-content: space-between;
      padding: 15px;
      background-color: white;
    }
    .table_Info{
      min-height: 800px;
      padding-left: 15px;
      padding-right: 15px;
      background-color: white;
    }
  }
}
</style>
